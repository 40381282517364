import React, { forwardRef, useEffect } from 'react';
import MaterialTable from 'material-table';
import styled from 'styled-components';

import {
  SortDownIcon,
  LastPageIcon,
  FirstPageIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  SearchIcon,
} from '../../../components/atoms/Icons';

import { ClearIcon } from '../../../components/atoms/Icons/ClearIcon';
import { ErrorBoundary } from '../../../components/molecules/ErrorBoundary';
import { User } from '../../../generated/gql/graphql';

export const UsersViewTable: React.FC<UsersTableProps> = ({ users }: UsersTableProps) => {
  // https://stackoverflow.com/questions/59648434/material-table-typeerror-cannot-add-property-tabledata-object-is-not-extensibl
  // Bug so we need to clone the data
  const clonedData = users.map((user) => ({ ...user }));

  return (
    <ErrorBoundary>
      <MaterialTable
        icons={tableIcons}
        columns={[
          {
            title: 'Email',
            field: 'email',
            render: (user) => {
              return user.email;
            },
          },
          {
            title: 'nickname',
            field: 'nickname',
          },
          {
            title: 'credits',
            field: 'credits',
          },
          {
            title: 'isAdmin',
            field: 'isAdmin',
          },
          {
            title: 'isEmailVerified',
            field: 'isEmailVerified',
          },
          {
            title: 'isMember',
            field: 'isMember',
          },
        ]}
        data={clonedData}
        title="Your users"
        options={{
          sorting: false,
          filtering: false,
          search: false,
        }}
      />
    </ErrorBoundary>
  );
};

const tableIcons: any = {
  FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <SortDownIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeftIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
};

interface UsersTableProps {
  users: User[];
}

export default UsersViewTable;
