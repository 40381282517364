import * as Yup from 'yup';

import { useUserContext } from '../../../contexts/UserContext';
import { H1 } from '../../../components/atoms/Typography';
import { useGetAllUsers } from '../../../graph/queries/getAllUsers';
import { UsersViewTable } from './UsersTable.styles';

export const Users = () => {
  const user = useUserContext();

  const { data: { getAllUsers = [] } = {}, loading } = useGetAllUsers();

  if (user?.isAdmin === false) {
    return null;
  }

  return (
    <>
      <H1>Admin Users</H1>
      {/* <pre>{JSON.stringify(getAllUsers, null, 2)}</pre> */}
      <UsersViewTable users={getAllUsers ? getAllUsers : []}></UsersViewTable>
    </>
  );
};

const validationSchema = Yup.object().shape({
  url: Yup.string().required('Please enter website'),
});

type FormData = Yup.InferType<typeof validationSchema>;
