import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query } from '../../generated/types';

export const GET_ALL_USERS = gql`
  query getAllUsers {
    getAllUsers {
      uuid
      isDeleted
      nickname
      email
      # lastLoginAt
      credits
      extraCredits
      isAdmin
      isMember
      # stripeUserId
      isEmailVerified
    }
  }
`;

export const useGetAllUsers = (options?: QueryHookOptions<Pick<Query, 'getAllUsers'>>) =>
  useQuery<Pick<Query, 'getAllUsers'>>(GET_ALL_USERS, options);

export const useLazyGetAllUsers = (options?: QueryHookOptions<Pick<Query, 'getAllUsers'>>) =>
  useLazyQuery<Pick<Query, 'getAllUsers'>>(GET_ALL_USERS, options);
